import ApiClass from './base';

class AccountApiClass extends ApiClass {


    checkEmail = payload =>
        this.makeRequest({
            url: `${this.url}/check_org_email`,
            data: {
                method: 'POST',
                body: payload,
            },
        });


    domainInfo = () => {
        return this.makeRequest({
            url: `${this.url}/info`,
            data: {
                method: 'GET'
            }
        })
    }

    sendSms = payload =>
        this.makeRequest({
            url: `${this.url}/send_org_sms`,
            data: {
                method: 'POST',
                body: payload
            }
        });

    sendEmail = payload =>
        this.makeRequest({
            url: `${this.url}/send_org_email`,
            data: {
                method: 'POST',
                body: payload
            }
        });

    createEmployee = payload =>
        this.makeRequest({
            url: `${this.url}/org_employee_import`,
            data: {
                method: 'POST',
                body: payload
            }
        })

    sendReverificationEmail = payload =>
        this.makeRequest({
            url: `${this.url}/resend_email_verification`,
            data: {
                method: 'POST',
                body: payload
            }
        })



}

const AccountApis = new AccountApiClass();
export default AccountApis;