// import { baseUrl } from '../utils/consts';

export default class ApiClass {
  constructor() {
    this.url = process.env.BACKEND_API_URL;
    this.generateApiUrl();
  }

  generateApiUrl = () => {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      const hostname = window.location.hostname
      const subdomain = hostname.split(".")[0];
      this.url = `${process.env.BACKEND_API_URL}/${subdomain}`;
    }
  }

  makeQueryString = params =>
    Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');

  getHeaders = options => {
    return Object.assign(options, {
      headers: new Headers(
        Object.assign(
          {
            'Content-Length': options.body ? options.body.length : 0,
            'Content-Type': 'application/json',
          },
          {},
        ),
      ),
    });
  };

  makeRequest = ({ url, data }) => {
    // eslint-disable-next-line no-param-reassign
    data = this.getHeaders(data);
    return new Promise((resolve, reject) => {
      fetch(url, data)
        .then(response => {
          if (response.status === 204) {
            resolve({ message: 'Successfully deleted.' });
          }
          if (response.status === 500) {
            reject({ message: "Something went wrong." });
          }
          response.json().then(parsedData => {
            if (response.status === 401) {
              return true;
            }
            if (response.status >= 300) {
              reject(parsedData);
            }
            resolve(parsedData);
          });
        })
        .catch(e => {
          reject(Error(e));
        });
    });
  };
}