import React, { useRef, useEffect } from "react"
import popupSuccess from "../../images/popupSuccess.svg"
import popupError from "../../images/popupError.svg"
import popupResend from "../../images/popupResend.svg"

const useOutsideAlert = (ref, handleClick) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) handleClick()
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
}

const Popup = ({
  title,
  description,
  buttonLabel,
  handleClick,
  showEmailInput,
  companyDomain,
  popupType,
  togglePopup,
  setMail
}) => {
  const wrapperRef = useRef(null)
  useOutsideAlert(wrapperRef, togglePopup)
  let popupImg =
    popupType && popupType === "success"
      ? popupSuccess
      : popupType === "error"
      ? popupError
      : popupResend
  return (
    <div className="b2b-popup-outer">
      <div className="b2b-popup-box" ref={wrapperRef}>
        <img
          src={popupImg}
          alt="b2b Popup Header"
          className="b2b-popup-top-image"
        />
        <div className="b2b-popup-main-container">
          <h4 className="b2b-popup-main-header">{title}</h4>
          <p className="b2b-popup-sub-header">{description}</p>
          {showEmailInput && (
            <div className="b2b-popup-email-input">
              <input
                type="text"
                placeholder="Official e-mail ID"
                name="b2b-email"
                onChange={(e) => setMail(e.target.value)}
              />
              <p>{`${companyDomain}`}</p>
            </div>
          )}
          <div className="b2b-popup-button-container">
            <button
              className="b2b-popup-button b2b-popup-confirm-button"
              onClick={handleClick}
            >
              {buttonLabel.toUpperCase()}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Popup
